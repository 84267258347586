<template>
  <section class="section-add">
    <div class="title">
      <el-button icon="el-icon-arrow-left" class="back" @click="back">返回</el-button>
      {{admin_id == 0 ? '新建账号' : '编辑账号'}}
    </div>
    <div class="form">
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> 姓名
        </el-col>
        <el-col :span="20">
          <el-input placeholder="请输入姓名" v-model="form.name" maxlength="10"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> 账号
        </el-col>
        <el-col :span="20">
          <el-input placeholder="请输入账号" v-model="form.mobile" maxlength="11"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
        </el-col>
        <el-col :span="20">
          <el-button type="primary" @click="submit">保存</el-button>
          <el-button type="danger" @click="back">取消</el-button>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
import { req, errorMsg } from '@/assets/js/util.js'

export default {
  data() {
    return {
      admin_id: 0,
      form: {
        name: '',
        mobile: ''
      }
    }
  },
  methods: {
    back() {
      this.$router.replace('/managerList')
    },
    getData() {
      return this.form
    },
    checkData() {
      if (!this.form.name) {
        errorMsg('请填写姓名!')
        return false
      }
      if (!this.form.mobile) {
        errorMsg('请填写账号!')
        return false
      }
      if ((Number(this.form.mobile) + '').length !== 11) {
        errorMsg('请填写正确的账号!')
        return false
      }
      return true
    },
    submit() {
      if (!this.checkData()) { return false }
      this.postData()
    },
    // submit() {
    //   if (!this.checkData()) { return false }
    //   this.$confirm('确认发布?', '提示', {
    //     type: 'warning'
    //   }).then(() => {
    //     this.postData(1, 1)
    //   })
    // },
    postData() {
      req({
        url: '/manage?cmd=101',
        param: {
          name: this.form.name,
          mobile: this.form.mobile,
          admin_id: this.admin_id
        }
      }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        setTimeout(() => {
          this.$router.replace('/managerList')
        }, 1500)
      })
    },
    getDetail() {
      req({
        url: '/manage?cmd=105',
        param: {
          admin_id: this.admin_id
        }
      }).then(res => {
        this.form.name = res.data.data.name
        this.form.mobile = res.data.data.mobile
      })
    }
  },
  created() {
    this.admin_id = this.$route.query.admin_id || 0
    if (this.admin_id) {
      this.getDetail()
    }
  }
}
</script>
<style lang="less" scope>
.section-add {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  .title {
    width: 100%;
    border-bottom: 1px solid #e4e1e1;
    padding: 17px 0 18px 0;
    text-align: center;
  }
}
.back {
  position: absolute;
  left: 15px;
  top: 10px;
}
.form {
  width: 600px;
  margin: 20px 30px;
  .el-row {
    padding: 10px 0;
    .el-col-4 {
      text-align: right;
      padding-top: 6px;
      span {
        color: red;
      }
    }
  }
}
.el-upload-list__item-name {
  display: none!important;
}
.el-upload-list.el-upload-list--picture {
  width: 100px;
}
</style>
