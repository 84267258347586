<template>
  <section class="h100" @click="showRight = false">
    <h2 class="main-title">
      图书管理
    </h2>
    <div class="condition">
      <el-button type="primary" size="medium" @click="addNew">录入新书</el-button>
      <el-button size="medium" @click="importDialog = true">导入excel</el-button>
      <!-- <el-button type="danger" size="medium" style="margin-left: 10px;" @click="bacth_del" v-if="selected.length > 0">批量删除</el-button> -->
      <el-input v-model="keyword" placeholder="请输入书名/作者/ISBN" style="float: right" suffix-icon="el-icon-search"></el-input>
    </div>
    <!-- end cond -->
    <div class="table">
      <template>
        <el-table height="100%" v-loading="loading" :data="info.list_item" border style="width: 100%" @selection-change="getSelected" @row-click="rowClick">
          <!-- <el-table-column v-if="showOption('jlmanage_delete_build') || true" type="selection" width="40"></el-table-column> -->
          <el-table-column prop="isbn_code" label="ISBN码" width=""></el-table-column>
          <el-table-column prop="book_name" label="书名" width=""></el-table-column>
          <el-table-column prop="author" label="作者" width=""></el-table-column>
          <el-table-column prop="update_time" label="录入时间" width="200px">
            <template slot-scope="scope">
              {{new Date(Number(scope.row.input_time) * 1000).toLocaleString()}}
            </template>
          </el-table-column>
          <el-table-column label="状态" width="">
            <template slot-scope="scope">
              {{scope.row.state == 1 ? "可借" : "不可借"}}
            </template>
          </el-table-column>
          <el-table-column prop="borrow_num" label="已借/本" width=""></el-table-column>
          <el-table-column prop="total_num" label="数量/本" width=""></el-table-column>
          <el-table-column label="操作" width="">
            <template slot-scope="scope">
              <el-button @click.stop="edit(scope.row)" type="text" size="small">修改</el-button>
              <el-button @click.stop="del(scope.row)" type="text" size="small">删除</el-button>
              <el-button v-if="Number(scope.row.borrow_num) > 0" @click.stop="huan(scope.row.book_id)" type="text" size="small">还书</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <!-- end table -->
    <Pagi v-if="!loading" :page="page" :pageSize="pageSize" :count="info.list_info.total_num" @pageChange="pageChange" />
    <!-- end page -->
    <Upload :show="importDialog" :templateUrl="templateUrl" uploadUrl="/publicClass?cmd=100" importUrl="/book?cmd=107" @updateVisible="(val) => {this.importDialog = val}"/>
    <!-- end upload -->
    <Right :show="showRight" @hideRight="showRight = false" title="详情">
      <template slot="content">
        <template v-for="(item, index) in viewDict">
          <div class="item" :key="index">
            <el-row :gutter="20">
              <el-col style="text-align: right;color:#9b9b9b;" :span="6">{{item.name}}</el-col>
              <el-col style="color:#4a4a4a;white-space:pre-wrap;" :span="18" v-html="rightDetail[item.key]"></el-col>
            </el-row>
          </div>
        </template>
      </template>
      <template slot="btn" v-if="rightDetail.record && rightDetail.record.length > 0">
        <el-button type="primary" @click.stop="huan(rightDetail.book_id)">还书</el-button>
      </template>
    </Right>
    <!-- end right -->
    <el-dialog title="选择还书人员" width="400px" :visible.sync="dialogHuan" @close="dialogHuan= false">
      <el-row :gutter="10">
        <el-col :span="4" style="line-height:35px;">
          人员
        </el-col>
        <el-col :span="20">
          <el-select v-model="userId" placeholder="请选择" :clearable="true">
            <el-option
              v-for="item in userList"
              :key="item.user_id"
              :label="item.name"
              :value="item.user_id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click.stop="dialogHuan = false">取 消</el-button>
        <el-button type="primary" @click="doHuan">确定</el-button>
      </span>
    </el-dialog>
  </section>
</template>
<script>
import Pagi from '@/components/pagi/'
import Upload from '@/components/upload/'
import Right from '@/components/right/'
import { req, errorMsg } from '@/assets/js/util.js'

export default {
  data () {
    return {
      power: [],
      keyword: '',
      page: 1,
      pageSize: 20,
      loading: true,
      info: [],
      selected: [],
      importDialog: false,
      templateUrl: '',
      showRight: false,
      dialogHuan: false,
      viewDict: [
        {
          key: 'isbn_code',
          name: 'ISBN码'
        },
        {
          key: 'book_name',
          name: '书名'
        },
        {
          key: 'detail',
          name: '详情'
        },
        {
          key: 'author',
          name: '作者'
        },
        {
          key: 'create_time_text',
          name: '录入时间'
        },
        {
          key: 'state_text',
          name: '状态'
        },
        {
          key: 'total_num',
          name: '数量/本'
        },
        {
          key: 'borrow_num',
          name: '已借/本'
        },
        {
          key: 'record_text',
          name: '借阅人员'
        }
      ],
      rightDetail: {},
      userId: '',
      userList: []
    }
  },
  components: {
    Pagi,
    Upload,
    Right
  },
  methods: {
    showOption (undoStr) {
      return this.power.indexOf(undoStr) !== -1
    },
    getLists (page) {
      req({
        url: '/book?cmd=100',
        param: {
          keyword: this.keyword,
          page: page || 1,
          page_size: this.pageSize
        }
      }).then(res => {
        this.loading = false
        this.info = res.data.data
        this.templateUrl = this.info.import_demo
      })
    },
    pageChange (val) {
      this.page = val
      document.documentElement.scrollTop = 0
      this.getLists(val)
    },
    async edit (row) {
      this.$router.push('/add/?book_id=' + row.book_id)
    },
    getSelected (arr) {
      this.selected = arr
    },
    del (row) {
      this.delFun(row.book_id)
    },
    // bacth_del () {
    //   if (this.selected.length === 0) {
    //     this.$common.error('请先选择要删除的数据!')
    //   } else {
    //     let ids = []
    //     for (let index in this.selected) {
    //       ids.push(this.selected[index]['build_id'])
    //     }
    //     this.delFun(ids)
    //   }
    // },
    delFun (id) {
      this.$confirm('删除后将无法恢复!', '提示', {
        type: 'danger'
      }).then(() => {
        req({
          url: '/book?cmd=102',
          param: {
            book_id: id
          }
        }).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.getLists(Math.min(this.page, Math.ceil((this.info.list_info.total_num - 1) / this.pageSize)))
        })
      })
    },
    addNew () {
      this.$router.push('/add')
    },
    async rowClick (row, e) {
      e.stopPropagation()
      await this.getBookInfo(row.book_id)
      this.showRight = true
    },
    async getBookInfo(bookId) {
      let res = await req({
        url: '/book?cmd=103',
        param: {
          book_id: bookId
        }
      })
      this.rightDetail = res.data.data
      this.rightDetail.create_time_text = new Date(Number(this.rightDetail.input_time) * 1000).toLocaleString()
      this.rightDetail.state_text = Number(this.rightDetail.state) === 1 ? '可借' : '不可借'
      let recordText = []
      this.rightDetail.record.forEach(item => {
        recordText.push(item.name)
      })
      this.userList = this.rightDetail.record
      this.rightDetail.record_text = recordText.join(', ') || '无'
    },
    async getBorrowInfo(bookId) {
      let res = await req({
        url: '/book?cmd=104',
        param: {
          book_id: bookId
        }
      })
      this.userList = res.data.data
    },
    huan(bookId) {
      this.userId = ''
      this.dialogHuan = true
      this.bookId = bookId
      this.getBorrowInfo(bookId)
    },
    doHuan() {
      if (!this.userId) {
        errorMsg('请选择人员!')
        return false
      }
      req({
        url: '/book?cmd=105',
        param: {
          user_id: this.userId,
          book_id: this.bookId
        }
      }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.showRight = false
        this.dialogHuan = false
        this.getLists(Math.min(this.page, Math.ceil((this.info.list_info.total_num - 1) / this.pageSize)))
      })
    }
  },
  watch: {
    'keyword': function () {
      this.getLists()
    }
  },
  created () {
    this.getLists()
  }
}
</script>
<style lang="less" scoped>
.condition {
  font-size: 14px;
  margin-bottom: 10px;
}
.el-input {
  width: 250px;
}
.table {
  margin: 15px 0;
}
.el-row {
  margin: 10px 0;
}
.lh28 {
  line-height: 28px;
}
</style>
<style lang="less">
#build-edit {
  .el-dialog__body {
    padding: 10px 25px;
  }
  .el-input__inner {
    font-size: 14px;
  }
  .lh34 {
    line-height: 34px;
    text-align: right;
  }
}
</style>
