import Vue from 'vue'
import Router from 'vue-router'
import login from './views/login.vue'

import index from './views/index.vue'
import booklist from './views/booklist.vue'
import managerList from './views/managerList.vue'
import managerAdd from './views/managerAdd.vue'
import add from './views/add.vue'

import account from './views/account.vue'
import userAdd from './views/userAdd.vue'
import pwd from './views/pwd.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/booklist'
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/',
      name: 'index',
      component: index,
      children: [{
        path: 'booklist',
        component: booklist
      }, {
        path: 'add',
        component: add
      }, {
        path: 'managerList',
        component: managerList
      }, {
        path: 'managerAdd',
        component: managerAdd
      }, {
        path: 'account',
        component: account
      }, {
        path: 'userAdd',
        component: userAdd
      }, {
        path: 'pwd',
        component: pwd
      }]
    }
  ]
})
