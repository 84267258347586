<template>
  <section class="h100">
    <h2 class="main-title">
      管理员管理
    </h2>
    <div class="condition">
      <el-button type="primary" size="medium" @click="addNew">新建管理员</el-button>
      <el-input v-model="keyword" placeholder="请输入姓名" style="float: right" suffix-icon="el-icon-search"></el-input>
    </div>
    <!-- end cond -->
    <div class="table">
      <template>
        <el-table height="100%" v-loading="loading" :data="info.list_item" border style="width: 100%" @selection-change="getSelected">
          <!-- <el-table-column v-if="showOption('jlmanage_delete_build') || true" type="selection" width="40"></el-table-column> -->
          <el-table-column prop="name" label="姓名" width=""></el-table-column>
          <el-table-column prop="mobile" label="账号" width=""></el-table-column>
          <el-table-column label="操作" width="">
            <template slot-scope="scope">
              <el-button @click.stop="edit(scope.row)" v-if="scope.row.admin_id != 1" type="text" size="small">修改</el-button>
              <el-button @click.stop="del(scope.row)"  v-if="scope.row.admin_id != 1" type="text" size="small">删除</el-button>
              <el-button @click.stop="reset(scope.row)" type="text" size="small">重置密码</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <!-- end table -->
    <Pagi v-if="!loading" :page="page" :pageSize="pageSize" :count="info.list_info.total_num" @pageChange="pageChange" />
    <!-- end page -->
  </section>
</template>
<script>
import Pagi from '@/components/pagi/'
import { req } from '@/assets/js/util.js'

export default {
  data () {
    return {
      power: [],
      keyword: '',
      page: 1,
      pageSize: 20,
      loading: true,
      info: [],
      selected: [],
      importDialog: false
    }
  },
  components: {
    Pagi
  },
  methods: {
    showOption (undoStr) {
      return this.power.indexOf(undoStr) !== -1
    },
    getLists (page) {
      req({
        url: '/manage?cmd=104',
        param: {
          keyword: this.keyword,
          page: page || 1,
          page_size: this.pageSize
        }
      }).then(res => {
        this.loading = false
        this.info = res.data.data
        // this.getPower()
      })
    },
    pageChange (val) {
      this.page = val
      document.documentElement.scrollTop = 0
      this.getLists(val)
    },
    edit (row) {
      this.$router.push('/managerAdd/?admin_id=' + row.admin_id)
    },
    getSelected (arr) {
      this.selected = arr
    },
    del (row) {
      this.delFun(row.admin_id)
    },
    // bacth_del () {
    //   if (this.selected.length === 0) {
    //     this.$common.error('请先选择要删除的数据!')
    //   } else {
    //     let ids = []
    //     for (let index in this.selected) {
    //       ids.push(this.selected[index]['build_id'])
    //     }
    //     this.delFun(ids)
    //   }
    // },
    delFun (id) {
      this.$confirm('删除后，此账号将不是管理员!', '提示', {
        type: 'danger'
      }).then(() => {
        req({
          url: '/manage?cmd=103',
          param: {
            admin_id: id
          }
        }).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.getLists(Math.min(this.page, Math.ceil((this.info.list_info.total_num - 1) / this.pageSize)))
        })
      })
    },
    addNew () {
      this.$router.push('/managerAdd')
    },
    reset(row) {
      this.$confirm('确认重置密码?', '提示', {
        type: 'warning'
      }).then(() => {
        req({
          url: '/manage?cmd=102',
          param: {
            admin_id: row.admin_id
          }
        }).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
      })
    }
  },
  watch: {
    'keyword': function () {
      this.getLists()
    }
  },
  created () {
    this.getLists()
  }
}
</script>
<style lang="less" scoped>
.condition {
  font-size: 14px;
  margin-bottom: 10px;
}
.el-input {
  width: 250px;
}
.table {
  margin: 15px 0;
}
.el-row {
  margin: 10px 0;
}
.lh28 {
  line-height: 28px;
}
</style>
<style lang="less">
#build-edit {
  .el-dialog__body {
    padding: 10px 25px;
  }
  .el-input__inner {
    font-size: 14px;
  }
  .lh34 {
    line-height: 34px;
    text-align: right;
  }
}
</style>
