var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: { active: _vm.show },
      attrs: { id: "right" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return (function() {})($event)
        }
      }
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(this.title))]),
      _c("div", { staticClass: "content" }, [_vm._t("content")], 2),
      _c("div", { staticClass: "btn-area" }, [_vm._t("btn")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }