<template>

  <el-container class="page-login">
    <!-- logo -->
    <el-header class="login-header" height="240px">
      <h1 class="title">
        欢迎使用 图书管理系统
      </h1>
    </el-header>

    <!-- 账号登录 -->
    <el-main class="login-main">
      <div class="login-index" v-if="$route.path === '/login'">
        <p class="login-title">账号登录</p>
        <!-- <el-form :rules="rules" :model="formData" status-icon ref="form" :show-message="false"> -->
        <el-form :model="formData" status-icon ref="form" :show-message="false">
          <el-form-item prop="acct">
            <div class="account">
              <el-input placeholder="手机号" v-model="formData.acct"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div class="password">
              <el-input type="password" placeholder="密码" v-model="formData.password"></el-input>
            </div>
          </el-form-item>
          <el-form-item v-if="needImgCode">
            <div class="password" style="margin-top: -10px;">
              <el-input style="width: 80px;" type="text" placeholder="验证码" v-model="formData.code"></el-input>
              <img :src="imgCode" alt="验证码" style="width:80px; position: relative; top: 11px; left: 11px;cursor: poiner;" @click="refCode">
            </div>
          </el-form-item>
          <el-form-item>
            <div class="controler">
              <el-button type="primary" class="login-btn" @click="login">登录</el-button>
              <!-- <p class="controler-info green" @click="$router.push('/registry')">没有账号？立即注册</p> -->
            </div>
          </el-form-item>
        </el-form>
      </div>
      <router-view></router-view>
    </el-main>
    <!-- <foot></foot> -->
  </el-container>

</template>

<script>
import { prefix, req, errorMsg } from '@/assets/js/util.js'

export default {
  data() {
    let acctRule = (rule, value, callback) => {
      if (/^[a-zA-Z0-9_]{3,16}$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入3~16长度的数字、字母、下划线的账号'))
      }
    }
    return {
      imgCode: prefix + '/admin?cmd=102',
      formData: {
        acct: '',
        password: '',
        code: ''
      },
      rules: {
        acct: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          {
            validator: acctRule,
            message: '请输入3~16长度的数字、字母、下划线的账号',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 3,
            max: 16,
            message: '请输入3~16长度的数字、字母、下划线的密码',
            trigger: 'blur'
          }
        ]
      },
      markAcct: false,
      needImgCode: false
    }
  },

  created() {
    // 登录状态监测
    // this.$common.http({
    //   mt: 'post',
    //   url: '/infoApply/site/get-type'
    // }, true).then(res => {
    //   this.$router.push('/')
    // })
    this.keyupSubmit()
    this.isNeedImgCode()
  },

  mounted() {
    // 填充密码
    // this.$nextTick(() => {
    //   let query = this.$common.getCookie('my_acct')
    //   if (query) {
    //     let acctArr = query.split('&').map(item => {
    //       return item.split('=')[1]
    //     })
    //     this.formData = {
    //       acct: acctArr[0],
    //       password: acctArr[1]
    //     }
    //     this.cookieAcct = acctArr
    //     this.markAcct = true
    //   }
    // })
  },

  methods: {
    async login() {
      await req({
        url: '/admin?cmd=101&qy',
        param: {
          mobile: this.formData.acct,
          password: this.formData.password,
          code: this.formData.code
        },
        errCallback: res => {
          console.log(res)
          errorMsg(res.data.msg)
          if (Number(res.data.code) === 10001) {
            // this.imgCode = res.data.data.img_code
            this.isNeedImgCode()
            this.refCode()
          }
        }
      }).then(res => {
        window.localStorage.setItem('nickname', res.data.data.name)
        window.localStorage.setItem('issuper', res.data.data.is_super)
      })
      this.$router.push('/booklist')
    },
    // 记住密码
    // markAcctFn() {
    //   if (this.markAcct) {
    //     if (
    //       this.cookieAcct &&
    //       this.formData.acct === this.cookieAcct[0] &&
    //       this.formData.password === this.cookieAcct[1]
    //     ) {
    //       return
    //     }
    //     this.$refs.form.validate((value, callback) => {
    //       this.$refs.form.clearValidate()
    //       if (value) {
    //         const query = `acct=${this.formData.acct}&pass=${
    //           this.formData.password
    //         }`
    //         this.$common.setCookie({
    //           key: 'my_acct',
    //           value: query,
    //           expire: 7 * 24 * 60 * 60 * 1000
    //         })
    //       }
    //     })
    //   } else {
    //     this.$common.setCookie({ key: 'my_acct' }, true)
    //   }
    // },
    // 回车登录
    keyupSubmit() {
      const pThis = this
      document.onkeydown = e => {
        if (pThis.$route.path === '/login') {
          let _key = window.event.keyCode
          if (_key === 13) {
            pThis.login()
          }
        }
      }
    },
    refCode() {
      this.imgCode = prefix + '/admin?cmd=102&v=' + new Date().getTime()
    },
    async isNeedImgCode() {
      let res = await req({
        url: '/admin?cmd=100'
      })
      this.needImgCode = res.data.data.is_need
    }
  }
}
</script>

<style scoped lang="less">
.page-login {
  height: 100vh;
  .login-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../assets/img/home_bg.png) no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    .title {
      margin-left: 30px;
      font-size: 36px;
      color: #fff;
      font-family: PingFangSC-Semibold;
    }
  }
  .login-main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 0;
    height: calc(100vh - 300px);
    background: url(../assets/img/main_bg.png) no-repeat;
    background-size: cover;

    .login-title {
      width: 246px;
      margin: 20px 0;
      font-family: PingFangSC-Semibold;
      font-size: 24px;
      color: #000000;
      text-align: left;
    }

    .controler {
      text-align: center;
      cursor: pointer;
      .controler-check {
        overflow: hidden;
        width: 100%;
        .check-left {
          float: left;
          width: 50px;
        }
        .check-right {
          float: right;
        }
      }
      .login-btn {
        width: 100%;
        margin-top: 3px;
        height: 38px;
        border-radius: 4px;
        font-size: 14px;
      }
      .controler-info {
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }
}
</style>
