<template>
  <section>
    <Head></Head>
    <div id="main">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import Head from '@/components/banner/index.vue'

export default {
  data () {
    return {
      leftMenuList: [],
      showRight: false
    }
  },
  components: {
    Head
  },
  computed: {
  },
  methods: {
  },
  created () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lange="less">
/* #container {
  width: 100%;
  padding-left: 260px;
  box-sizing: border-box;
} */
</style>
