var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "h100",
      on: {
        click: function($event) {
          _vm.showRight = false
        }
      }
    },
    [
      _c("h2", { staticClass: "main-title" }, [_vm._v("\n    图书管理\n  ")]),
      _c(
        "div",
        { staticClass: "condition" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.addNew }
            },
            [_vm._v("录入新书")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "medium" },
              on: {
                click: function($event) {
                  _vm.importDialog = true
                }
              }
            },
            [_vm._v("导入excel")]
          ),
          _c("el-input", {
            staticStyle: { float: "right" },
            attrs: {
              placeholder: "请输入书名/作者/ISBN",
              "suffix-icon": "el-icon-search"
            },
            model: {
              value: _vm.keyword,
              callback: function($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: { height: "100%", data: _vm.info.list_item, border: "" },
                on: {
                  "selection-change": _vm.getSelected,
                  "row-click": _vm.rowClick
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "isbn_code", label: "ISBN码", width: "" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "book_name", label: "书名", width: "" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "author", label: "作者", width: "" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "update_time",
                    label: "录入时间",
                    width: "200px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                new Date(
                                  Number(scope.row.input_time) * 1000
                                ).toLocaleString()
                              ) +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "状态", width: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.state == 1 ? "可借" : "不可借") +
                              "\n          "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { prop: "borrow_num", label: "已借/本", width: "" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "total_num", label: "数量/本", width: "" }
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.edit(scope.row)
                                }
                              }
                            },
                            [_vm._v("修改")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.del(scope.row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          ),
                          Number(scope.row.borrow_num) > 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.huan(scope.row.book_id)
                                    }
                                  }
                                },
                                [_vm._v("还书")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]
        ],
        2
      ),
      !_vm.loading
        ? _c("Pagi", {
            attrs: {
              page: _vm.page,
              pageSize: _vm.pageSize,
              count: _vm.info.list_info.total_num
            },
            on: { pageChange: _vm.pageChange }
          })
        : _vm._e(),
      _c("Upload", {
        attrs: {
          show: _vm.importDialog,
          templateUrl: _vm.templateUrl,
          uploadUrl: "/publicClass?cmd=100",
          importUrl: "/book?cmd=107"
        },
        on: {
          updateVisible: function(val) {
            this$1.importDialog = val
          }
        }
      }),
      _c(
        "Right",
        {
          attrs: { show: _vm.showRight, title: "详情" },
          on: {
            hideRight: function($event) {
              _vm.showRight = false
            }
          }
        },
        [
          _c(
            "template",
            { slot: "content" },
            [
              _vm._l(_vm.viewDict, function(item, index) {
                return [
                  _c(
                    "div",
                    { key: index, staticClass: "item" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "right",
                                color: "#9b9b9b"
                              },
                              attrs: { span: 6 }
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c("el-col", {
                            staticStyle: {
                              color: "#4a4a4a",
                              "white-space": "pre-wrap"
                            },
                            attrs: { span: 18 },
                            domProps: {
                              innerHTML: _vm._s(_vm.rightDetail[item.key])
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _vm.rightDetail.record && _vm.rightDetail.record.length > 0
            ? _c(
                "template",
                { slot: "btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.huan(_vm.rightDetail.book_id)
                        }
                      }
                    },
                    [_vm._v("还书")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择还书人员",
            width: "400px",
            visible: _vm.dialogHuan
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogHuan = $event
            },
            close: function($event) {
              _vm.dialogHuan = false
            }
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { staticStyle: { "line-height": "35px" }, attrs: { span: 4 } },
                [_vm._v("\n        人员\n      ")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: true },
                      model: {
                        value: _vm.userId,
                        callback: function($$v) {
                          _vm.userId = $$v
                        },
                        expression: "userId"
                      }
                    },
                    _vm._l(_vm.userList, function(item) {
                      return _c("el-option", {
                        key: item.user_id,
                        attrs: { label: item.name, value: item.user_id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.dialogHuan = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doHuan } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }