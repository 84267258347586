var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-header", { staticClass: "banner", attrs: { height: "64px" } }, [
    _c("div", { staticClass: "banner-row", attrs: { type: "flex" } }, [
      _c(
        "h1",
        {
          staticClass: "logo pointer",
          on: {
            click: function($event) {
              _vm.$router.push("/")
            }
          }
        },
        [
          _c("span", { staticClass: "logo-text" }, [
            _vm._v("\n        图书管理系统\n      ")
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "nav",
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _c(
            "el-menu",
            {
              ref: "menu",
              attrs: {
                "default-active": _vm.routerIndex,
                router: true,
                "menu-trigger": "click",
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "text-color": "#4a4a4a"
              },
              on: { select: _vm.handleSelect }
            },
            [
              _vm._l(_vm.initList, function(item) {
                return _c(
                  "el-menu-item",
                  {
                    key: item.path,
                    staticClass: "banner-item",
                    attrs: { index: item.path, route: { path: item.path } }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(item.label) + "\n          "
                    ),
                    _c("span", { staticClass: "line" })
                  ]
                )
              }),
              _c(
                "el-menu-item",
                {
                  staticClass: "banner-item user",
                  attrs: {
                    index: "user",
                    disabled: "",
                    route: { path: "/user-base" }
                  }
                },
                [
                  _c(
                    "el-dropdown",
                    { attrs: { trigger: "click", "show-timeout": 30 } },
                    [
                      _c("div", { staticClass: "avatar" }, [
                        _c("span", { staticClass: "avatar-icon" })
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "banner-drop",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown"
                        },
                        [
                          _c("el-dropdown-item", { staticClass: "loginOut" }, [
                            _c("div", { staticClass: "inner" }, [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.nickname) +
                                    "\n                  "
                                )
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "label",
                                  on: { click: _vm.loginOut }
                                },
                                [_vm._v("退出")]
                              )
                            ])
                          ]),
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-dropdown-item", { key: item.path }, [
                              _c(
                                "div",
                                { staticClass: "inner" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "inner-route",
                                      attrs: {
                                        "active-class": "active",
                                        to: { path: item.path },
                                        tag: "div"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                ],
                                1
                              )
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }