<template>
  <el-header height="64px" class="banner">
    <div type="flex" class="banner-row">
      <h1 class="logo pointer" @click="$router.push('/');">
        <!-- <span class="logo-bg"></span> -->
        <span class="logo-text">
          图书管理系统
        </span>
      </h1>
      <div class="nav" @click.stop>
        <el-menu ref="menu" :default-active="routerIndex" :router="true" menu-trigger="click" mode="horizontal" @select="handleSelect" active-text-color="#409EFF" text-color="#4a4a4a">
          <el-menu-item class="banner-item" :index="item.path" :route="{path: item.path}" v-for="item in initList" :key="item.path">
            <!-- <span class="icon" v-if="item.icon"></span> -->
            {{item.label}}
            <span class="line"></span>
          </el-menu-item>
          <el-menu-item class="banner-item user" index="user" disabled :route="{path: '/user-base'}">
            <el-dropdown trigger="click" :show-timeout="30">
              <div class="avatar">
                <span class="avatar-icon"></span>
              </div>
              <el-dropdown-menu slot="dropdown" class="banner-drop">
                <el-dropdown-item class="loginOut">
                  <div class="inner">
                    <span class="name">
                      {{nickname}}
                    </span>
                    <span class="label" @click="loginOut">退出</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-for="item in userList" :key="item.path">
                  <div class="inner">
                    <router-link class="inner-route" active-class="active" :to="{path: item.path}" tag="div">{{item.label}}</router-link>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </el-header>
</template>

<script>
import { req, getAdminNickName } from '@/assets/js/util.js'

export default {
  props: {
    initList: {
      type: Array,
      default: () => {
        return [
          {
            path: '/booklist',
            label: '图书管理'
          },
          {
            path: '/account',
            label: '账号管理'
          }
          // {
          //   path: '/managerList',
          //   label: '管理员管理'
          // }
        ]
      }
    },
    userList: {
      type: Array,
      default: () => {
        return [{
          label: '修改密码',
          path: '/pwd'
        }]
      }
    }
  },

  data() {
    return {
      nickname: ''
    }
  },

  created() {
    this.nickname = getAdminNickName()
    let issuper = window.localStorage.getItem('issuper')
    if (issuper && Number(issuper) === 1) {
      this.initList.push({
        path: '/managerList',
        label: '管理员管理'
      })
    }
  },

  methods: {
    handleSelect(select) {
      this.$router.push(select)
    },
    async loginOut() {
      await req({
        url: '/admin?cmd=103'
      })
      this.$router.replace('login')
    }
  },

  computed: {
    routerIndex() {
      console.log(this.$route.path)
      return this.$route.path
    }
  }
}
</script>

<style scoped lang="less">
.el-menu-item.banner-item.user.is-disabled {
  opacity: 1;
  cursor: pointer;
}
.banner {
  width: 100%;
  background: #ffffff;
  height: 64px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  .banner-row {
    width: 1480px;
    margin: 0 auto;
    overflow: hidden;
    @media screen and (max-width: 1480px) {
      width: 100%;
    }
    .logo {
      float: left;
      height: 100%;
      .logo-bg {
        display: inline-block;
        // width: 50px;
        height: 64px;
        // margin-right: 16px;
        // background: url(../../assets/img/icon_logo.png) no-repeat center;
        // background-size: 100% 46px;
      }
      .logo-text {
        // font-size: 20px;
        // display: inline-block;
        // vertical-align: 24px;
        font-size: 32px!important;
        font-family: STHeitiSC-Medium;
        color: #345d8d;
        line-height: 62px;
      }
    }
    .nav {
      float: right;
      font-size: 14px;
    }
    .banner-item {
      position: relative;
      height: 63px;
      border: none;
      .icon {
        display: inline-block;
        width: 23px;
        height: 23px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url(../../assets/img/icon_header.png);
      }
      &.is-active {
        .icon {
          background-image: url(../../assets/img/icon_header_active.png);
        }
      }
      .line {
        position: absolute;
        right: 0px;
        top: calc(50% - 12px);
        width: 2px;
        height: 24px;
        background: #eaeaea;
      }
      &.user {
        padding: 0;
        text-align: center;
        .avatar {
          height: 100%;
          width: 60px;
          .avatar-icon {
            display: inline-block;
            width: 27px;
            height: 24px;
            background: url(../../assets/img/avatar.png) no-repeat center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.banner-drop {
  width: 216px;
  .el-dropdown-menu__item {
    &:last-of-type {
      border-bottom: none;
    }
    height: 50px !important;
    line-height: 50px !important;
    width: 100% !important;
    z-index: 999;
    overflow: hidden;
    border-bottom: 1px solid #ececec;
    padding: 0 !important;
    color: #000;
    .inner-route {
      padding: 0 30px;
      width: calc(100% - 60px);
    }
    &:hover {
      color: #4c9b95;
      background: #ebf6f7;
    }
    &.loginOut {
      cursor: default;
      &:hover {
        background: #fff;
        color: #000;
      }
      .name,
      .label {
        display: inline-block;
      }
      .name {
        width: 78%;
      }
      .label {
        cursor: pointer;
        color: #2acdcf;
      }
      .inner {
        padding: 0 30px;
      }
    }
  }
  .popper__arrow {
    display: none;
  }
}
</style>
