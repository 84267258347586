var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section-add" }, [
    _c("div", { staticClass: "title" }, [_vm._v("\n    修改密码\n  ")]),
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" 原密码\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 18 } },
              [
                _c("el-input", {
                  attrs: {
                    type: "password",
                    placeholder: "请输入原密码",
                    maxlength: "99"
                  },
                  model: {
                    value: _vm.form.old_password,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "old_password", $$v)
                    },
                    expression: "form.old_password"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" 新密码\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 18 } },
              [
                _c("el-input", {
                  attrs: {
                    type: "password",
                    placeholder: "请输入新密码",
                    maxlength: "99"
                  },
                  model: {
                    value: _vm.form.new_password,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "new_password", $$v)
                    },
                    expression: "form.new_password"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" 确认新密码\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 18 } },
              [
                _c("el-input", {
                  attrs: {
                    type: "password",
                    placeholder: "请确认新密码",
                    maxlength: "99"
                  },
                  model: {
                    value: _vm.form.re_new_password,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "re_new_password", $$v)
                    },
                    expression: "form.re_new_password"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 6 } }),
            _c(
              "el-col",
              { attrs: { span: 18 } },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "danger" }, on: { click: _vm.back } },
                  [_vm._v("取消")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }