<template>
  <section class="section-add">
    <div class="title">
      <el-button icon="el-icon-arrow-left" class="back" @click="back">返回</el-button>
      {{$route.query.book_id ? '修改书籍' : '录入新书'}}
    </div>
    <div class="form">
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> ISBN码
        </el-col>
        <el-col :span="20">
          <el-input :disabled="Boolean($route.query.book_id)" placeholder="请输入ISBN码" v-model="form.isbn_code" maxlength="99" style="width: 350px;margin-right: 10px;"></el-input>
          <el-button @click="fetch">匹配</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          图片
        </el-col>
        <el-col :span="20">
          <el-upload class="upload-demo" :on-success="afterUpload" :on-remove="remove" :before-upload="beforeUpload" :action="prefix + '/publicClass?cmd=100'" list-type="picture" :limit="1" :file-list="form.fileList" with-credentials>
            <el-button v-if="form.fileList.length == 0">上传</el-button>
            <span class="fs12" style="color: #bbb;" v-if="form.fileList.length == 0"> (图片大小5M以内) </span>
          </el-upload>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> 书名
        </el-col>
        <el-col :span="20">
          <el-input placeholder="请输入书名" v-model="form.book_name" maxlength="99"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> 作者
        </el-col>
        <el-col :span="20">
          <el-input placeholder="请输入作者" v-model="form.author" maxlength="99"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          详情
        </el-col>
        <el-col :span="20">
          <el-input type="textarea" placeholder="请输入详情" v-model="form.detail" maxlength="500"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> 总量
        </el-col>
        <el-col :span="20">
          <el-input-number :disabled="Boolean($route.query.book_id && Number(this.form.borrow_num) > 0)" size="small" v-model="form.total_num" :max="999" :min="1"></el-input-number>
          <!-- <el-input placeholder="请输入总量" v-model="form.total" type="number" step="1"></el-input> -->
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
        </el-col>
        <el-col :span="20">
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button type="danger" @click="back">取消</el-button>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
import { req, prefix, errorMsg } from '@/assets/js/util.js'

export default {
  data() {
    return {
      options: [],
      form: {
        book_id: 0,
        isbn_code: '',
        book_name: '',
        total_num: 1,
        author: '',
        detail: '',
        fileList: []
      },
      book_id: '',
      prefix: prefix
    }
  },
  methods: {
    fetch() {
      req({
        url: '/book?cmd=106',
        param: {
          isbn_code: this.form.isbn_code
        }
      }).then(res => {
        let data = res.data.data
        this.form.book_name = data.BookName
        this.form.author = data.Author
        this.form.fileList = data.Photo ? [{
          url: data.Photo,
          name: '',
          path: ''
        }] : []
      })
    },
    back() {
      this.$router.replace('/booklist')
    },
    getData() {
      return this.form
    },
    checkData() {
      let flag = true
      let form = {
        isbn_code: {
          desc: '请输入ISBN码!'
        },
        book_name: {
          desc: '请填写书名!'
        },
        total_num: {
          desc: '请填写总量!'
        }
      }
      for (let key in form) {
        let val = this.form[key]
        if (!val) {
          errorMsg(form[key].desc)
          flag = false
          break
        }
      }
      return flag
    },
    save() {
      if (!this.checkData()) { return false }
      this.postData(0, 0)
    },
    submit() {
      if (!this.checkData()) { return false }
      this.form.logo = (this.form.fileList[0] && this.form.fileList[0].path) || (this.form.fileList[0] && this.form.fileList[0].url) || ''
      req({
        url: '/book?cmd=101',
        param: this.form
      }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        setTimeout(() => {
          this.$router.replace('/booklist')
        }, 1500)
      })
    },
    getDetail() {
      req({
        url: '/book?cmd=103',
        param: {
          book_id: this.book_id
        }
      }).then(res => {
        let data = res.data.data
        this.form = data
        this.form.fileList = data.logo_url ? [{
          url: data.logo_url,
          path: data.logo,
          name: ''
        }] : []
      })
    },
    afterUpload(res, file, fileList) {
      console.log(res)
      console.log(file)
      if (Number(res.code) !== 10000) {
        errorMsg(res.msg)
        this.form.fileList = []
        return false
      }
      this.form.fileList = [{
        name: '',
        url: res.data.real_path,
        path: res.data.relative_path
      }]
    },
    beforeUpload(file) {
      if (file.size / 1000 / 1000 > 5) {
        errorMsg('图片大小不能超过5m')
        return false
      }
    },
    remove() {
      let tmp = JSON.parse(JSON.stringify(this.form))
      tmp.fileList = []
      this.form = tmp
    }
  },
  created() {
    this.form.book_id = this.$route.query.book_id || 0
    this.book_id = this.$route.query.book_id || 0
    if (this.book_id) {
      this.getDetail()
    }
  }
}
</script>
<style lang="less" scope>
.section-add {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  .title {
    width: 100%;
    border-bottom: 1px solid #e4e1e1;
    padding: 17px 0 18px 0;
    text-align: center;
  }
}
.back {
  position: absolute;
  left: 15px;
  top: 10px;
}
.form {
  width: 600px;
  margin: 20px 30px;
  .el-row {
    padding: 10px 0;
    .el-col-4 {
      text-align: right;
      padding-top: 6px;
      span {
        color: red;
      }
    }
  }
}
.el-upload-list__item-name {
  display: none!important;
}
.el-upload-list.el-upload-list--picture {
  width: 100px;
}
.el-upload-list__item{
  margin-top: 0!important;
}
.el-upload-list--picture .el-upload-list__item{
  height: auto;
}
.el-upload-list--picture .el-upload-list__item-thumbnail{
  height: auto;
}
</style>
