<template>
  <section>
    <el-dialog class="importDialog" title="导入文件" :visible.sync="innerShow" width="500px">
      <div v-show="state == 0">
        <el-upload name="Filedata" style="width: 100%;" class="upload-demo" with-credentials drag :action="prefix + uploadUrl" :show-file-list="false" :before-upload="beforeUpload" :on-success="afterUpload" :data="{ext: 'file'}">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">可直接拖拽文件到这里上传或
            <em>浏览文件</em>
          </div>
        </el-upload>
        <el-button type="primary" @click="downloadTpl" size="small" style="margin-top: 15px;">下载模板文件</el-button>
      </div>
      <!-- <div v-show="state == 2" style="padding: 25px 0">
        <el-row :gutter="20">
          <el-col :span="15" style="line-height: 50px;text-align: right;">文件上传完成，正在导入</el-col>
          <el-col :span="7">
            <el-progress :width="50" :stroke-width="4" type="circle" :percentage="precent"></el-progress>
          </el-col>
        </el-row>
      </div> -->
      <div v-show="state == 2" style="padding: 15px 0">
        <el-row :gutter="20" class="errmsg">
          <el-col :span="3" class="err-left">
            <i class="el-icon-warning"></i>
          </el-col>
          <el-col :span="21" class="err-right">
            <h3 style="padding-bottom: 15px;">导入信息</h3>
            <p>导入成功{{importInfo.success_num}}条, 失败{{importInfo.fail_num}}条
              <a :href="importInfo.report_file" target="_blank" download v-if="importInfo.fail_num && importInfo.fail_num > 0">下载失败数据</a>
            </p>
          </el-col>
        </el-row>
      </div>
      <div v-show="state == 2" style="text-align: right; margin-right: -10px;">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="makeSure">确 定</el-button>
          <a :href="info.import_holiday_sample"></a>
        </span>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import { prefix, req, lock, unlock, errorMsg } from '@/assets/js/util.js'
export default {
  props: [
    'show',
    'templateUrl',
    'uploadUrl',
    'importUrl',
    'methodType'
  ],
  data () {
    return {
      innerShow: this.show,
      importDialog: false,
      importInfo: {},
      info: {},
      failFile: '',
      precent: 0,
      state: 0, // 0 - 未上传 1 - 上传中 2 -导入中 3-导入完成

      prefix: prefix
    }
  },
  watch: {
    'show': function (val) {
      this.innerShow = val
    },
    'innerShow': function (val) {
      this.$emit('updateVisible', val)
      this.state = 0
    }
  },
  methods: {
    beforeUpload (file) {
      lock()
    },
    afterUpload (res) {
      // this.state = 2
      unlock()
      if (Number(res.code) !== 10000) {
        errorMsg(res.msg)
        this.state = 0
        return false
      }
      // this.failFile = res.data.fail_file
      // this.partToPart(res.data)
      req({
        url: this.importUrl,
        param: {
          file: res.data.relative_path
        }
      }).then(res => {
        this.state = 2
        this.importInfo = res.data.data
      })
    },
    // async partToPart (info) {
    //   info.method = this.methodType
    //   let resp = await this.$common
    //     .http({
    //       mt: 'post',
    //       url: this.importUrl,
    //       data: info
    //     }, true).catch(err => {
    //       console.log(err)
    //     })
    //   const tmpInfo = resp.data.data
    //   let total = Number(tmpInfo.total)
    //   this.precent = total === 0 ? 100 : Math.min(Math.ceil((tmpInfo.success + tmpInfo.fail) / total * 100), 100)
    //   if (tmpInfo && tmpInfo.idx < total) {
    //     this.partToPart(tmpInfo)
    //   } else {
    //     if (tmpInfo.fail > 0) {
    //       this.state = 3
    //       this.importInfo = tmpInfo
    //       this.dialogVisible = true
    //     } else {
    //       // this.innerShow = false
    //       setTimeout(() => {
    //         location.reload()
    //       }, 500)
    //     }
    //   }
    // },
    makeSure () {
      // this.innerShow = false
      location.reload()
    },
    downloadTpl () {
      window.open(this.templateUrl)
    }
  },
  created () {
  }
}
</script>
<style lang="less">
.importDialog {
  .el-dialog__body {
    padding-top: 15px;
  }
  .el-upload,
  .el-upload-dragger {
    width: 100%;
  }
}
.errmsg {
  border: 1px solid #fb6a7f;
  .err-left {
    background: rgb(243, 225, 229);
    color: #fb687d;
    line-height: 80px;
    text-align: center;
  }
  .err-right {
    padding-top: 20px;
  }
  p {
    color: #999;
  }
  a {
    color: #409eff;
  }
}
</style>
