var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section-add" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c(
          "el-button",
          {
            staticClass: "back",
            attrs: { icon: "el-icon-arrow-left" },
            on: { click: _vm.back }
          },
          [_vm._v("返回")]
        ),
        _vm._v(
          "\n    " +
            _vm._s(_vm.admin_id == 0 ? "新建账号" : "编辑账号") +
            "\n  "
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" 姓名\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入姓名", maxlength: "10" },
                  model: {
                    value: _vm.form.name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" 账号\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入账号", maxlength: "11" },
                  model: {
                    value: _vm.form.mobile,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "mobile", $$v)
                    },
                    expression: "form.mobile"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "danger" }, on: { click: _vm.back } },
                  [_vm._v("取消")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }