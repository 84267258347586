var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.count > 0
    ? _c(
        "section",
        [
          _c("div", { attrs: { id: "page-info" } }, [
            _vm._v(
              "第" +
                _vm._s((_vm.page - 1) * _vm.pageSize + 1) +
                " ~ " +
                _vm._s(Math.min(_vm.page * _vm.pageSize, _vm.count)) +
                "条记录，共" +
                _vm._s(_vm.count) +
                "条记录"
            )
          ]),
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next",
              "page-size": _vm.pageSize,
              total: _vm.count
            },
            on: { "current-change": _vm.pageChange }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }