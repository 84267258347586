import { Message, Loading } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import axios from 'axios'
import router from '../../router'
// import qs from 'querystring'

let loadingInstance = {}

const testPrefix = 'https://test.api-qy.zh-library.3ruler.com'
const truePrefix = 'https://api-qy.zh-library.3ruler.com'

const prefix = process.env.VUE_APP_IS_LOCAL === '1' ? '' : location.href.indexOf('//test') !== -1 ? testPrefix : truePrefix

const req = function({ url, param = {}, callback, errCallback }) {
  lock()
  let formData = new FormData()
  for (let key in param) {
    let data = param[key]
    let formatData = typeof data === 'object' ? JSON.stringify(data) : data
    formData.append(key, formatData)
  }
  let _promise = new Promise(function (resolve, reject) {
    axios({
      url: prefix + url,
      // url: url,
      method: 'post',
      withCredentials: true,
      header: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(res => {
      unlock()
      if (Number(res.data.code) === 10000) {
        if (callback && typeof callback === 'function') {
          callback(res)
        }
        resolve(res)
      } else if (Number(res.data.code) === 10003) {
        router.replace({
          path: 'login'
        })
      } else {
        if (errCallback && typeof errCallback === 'function') {
          errCallback(res)
        } else {
          errorMsg(res.data.msg)
        }
      }
    }).catch(res => {
      unlock()
      console.log('请求失败')
      errorMsg(JSON.stringify(res))
      reject(res)
    })
  })
  return _promise
}

const lock = function() {
  loadingInstance = Loading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.5)'
  })
}

const unlock = function() {
  loadingInstance.close()
}

const errorMsg = function(msg) {
  Message({
    customClass: 'err',
    type: 'error',
    message: msg,
    duration: 1500,
    showClose: true
  })
}

const trim = function(str) {
  return str.replace(/ /g, '')
}

const getAdminNickName = function() {
  return window.localStorage.getItem('nickname') || ''
}

export {
  prefix,
  req,
  lock,
  unlock,
  errorMsg,
  trim,
  getAdminNickName
}
