<template>
  <section class="section-add">
    <div class="title">
      <el-button icon="el-icon-arrow-left" class="back" @click="back">返回</el-button>
      {{user_id == 0 ? '新建账号' : '编辑账号'}}
    </div>
    <div class="form">
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> 姓名
        </el-col>
        <el-col :span="20">
          <el-input placeholder="请输入姓名" v-model="form.name" maxlength="10"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> 部门
        </el-col>
        <el-col :span="20">
          <el-cascader
            v-model="form.dept_id"
            placeholder="请选择部门"
            :options="depts"
            :props="defaultProps"
            :change-on-select="true"
            :show-all-levels="true"
          ></el-cascader>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <span>*</span> 联系方式
        </el-col>
        <el-col :span="20">
          <el-input placeholder="请输入联系方式" v-model="form.mobile" maxlength="11"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
        </el-col>
        <el-col :span="20">
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button type="danger" @click="back">取消</el-button>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
import { req, errorMsg } from '@/assets/js/util.js'

export default {
  data() {
    return {
      depts: [],
      user_id: 0,
      form: {
        name: '',
        dept_id: [],
        mobile: ''
      },
      defaultProps: {
        value: 'dept_id',
        label: 'name',
        children: 'sub',
        isLeaf: 'leaf'
      }
    }
  },
  methods: {
    back() {
      this.$router.replace('/account')
    },
    getData() {
      return this.form
    },
    checkData() {
      console.log(this.form)
      if (!this.form.name) {
        errorMsg('请填写姓名!')
        return false
      }
      if (this.form.dept_id && this.form.dept_id.length === 0) {
        errorMsg('请选择部门!')
        return false
      }
      if (!this.form.mobile) {
        errorMsg('请填写联系方式!')
        return false
      }
      if ((Number(this.form.mobile) + '').length !== 11) {
        errorMsg('请填写正确的联系方式!')
        return false
      }
      return true
    },
    submit() {
      if (!this.checkData()) { return false }
      this.postData()
    },
    // submit() {
    //   if (!this.checkData()) { return false }
    //   this.$confirm('确认发布?', '提示', {
    //     type: 'warning'
    //   }).then(() => {
    //     this.postData(1, 1)
    //   })
    // },
    postData() {
      req({
        url: '/user?cmd=105',
        param: {
          name: this.form.name,
          mobile: this.form.mobile,
          dept_id: this.form.dept_id[this.form.dept_id.length - 1],
          user_id: this.user_id
        }
      }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        setTimeout(() => {
          this.$router.replace('/account')
        }, 1500)
      })
    },
    getDetail() {
      req({
        url: '/user?cmd=107',
        param: {
          user_id: this.user_id
        }
      }).then(res => {
        this.form.name = res.data.data.name
        this.form.mobile = res.data.data.mobile
        let arr = (res.data.data.path && res.data.data.path.split('/')) || []
        arr.push(res.data.data.dept_id)
        this.form.dept_id = arr
      })
    },
    async getDepts() {
      let res = await req({
        url: '/user?cmd=100&qy'
      })
      this.depts = res.data.data
    }
  },
  created() {
    this.getDepts()
    this.user_id = this.$route.query.user_id || 0
    if (this.user_id) {
      this.getDetail()
    }
  }
}
</script>
<style lang="less" scope>
.section-add {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  .title {
    width: 100%;
    border-bottom: 1px solid #e4e1e1;
    padding: 17px 0 18px 0;
    text-align: center;
  }
}
.back {
  position: absolute;
  left: 15px;
  top: 10px;
}
.form {
  width: 600px;
  margin: 20px 30px;
  .el-row {
    padding: 10px 0;
    .el-col-4 {
      text-align: right;
      padding-top: 6px;
      span {
        color: red;
      }
    }
  }
}
.el-upload-list__item-name {
  display: none!important;
}
.el-upload-list.el-upload-list--picture {
  width: 100px;
}
</style>
