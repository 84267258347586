var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section-add" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c(
          "el-button",
          {
            staticClass: "back",
            attrs: { icon: "el-icon-arrow-left" },
            on: { click: _vm.back }
          },
          [_vm._v("返回")]
        ),
        _vm._v(
          "\n    " +
            _vm._s(_vm.$route.query.book_id ? "修改书籍" : "录入新书") +
            "\n  "
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" ISBN码\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c("el-input", {
                  staticStyle: { width: "350px", "margin-right": "10px" },
                  attrs: {
                    disabled: Boolean(_vm.$route.query.book_id),
                    placeholder: "请输入ISBN码",
                    maxlength: "99"
                  },
                  model: {
                    value: _vm.form.isbn_code,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "isbn_code", $$v)
                    },
                    expression: "form.isbn_code"
                  }
                }),
                _c("el-button", { on: { click: _vm.fetch } }, [_vm._v("匹配")])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _vm._v("\n        图片\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      "on-success": _vm.afterUpload,
                      "on-remove": _vm.remove,
                      "before-upload": _vm.beforeUpload,
                      action: _vm.prefix + "/publicClass?cmd=100",
                      "list-type": "picture",
                      limit: 1,
                      "file-list": _vm.form.fileList,
                      "with-credentials": ""
                    }
                  },
                  [
                    _vm.form.fileList.length == 0
                      ? _c("el-button", [_vm._v("上传")])
                      : _vm._e(),
                    _vm.form.fileList.length == 0
                      ? _c(
                          "span",
                          {
                            staticClass: "fs12",
                            staticStyle: { color: "#bbb" }
                          },
                          [_vm._v(" (图片大小5M以内) ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" 书名\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入书名", maxlength: "99" },
                  model: {
                    value: _vm.form.book_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "book_name", $$v)
                    },
                    expression: "form.book_name"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" 作者\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入作者", maxlength: "99" },
                  model: {
                    value: _vm.form.author,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "author", $$v)
                    },
                    expression: "form.author"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _vm._v("\n        详情\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入详情",
                    maxlength: "500"
                  },
                  model: {
                    value: _vm.form.detail,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "detail", $$v)
                    },
                    expression: "form.detail"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }, [
              _c("span", [_vm._v("*")]),
              _vm._v(" 总量\n      ")
            ]),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c("el-input-number", {
                  attrs: {
                    disabled: Boolean(
                      _vm.$route.query.book_id &&
                        Number(this.form.borrow_num) > 0
                    ),
                    size: "small",
                    max: 999,
                    min: 1
                  },
                  model: {
                    value: _vm.form.total_num,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "total_num", $$v)
                    },
                    expression: "form.total_num"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 4 } }),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v("确定")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "danger" }, on: { click: _vm.back } },
                  [_vm._v("取消")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }