<template>
  <section class="section-add">
    <div class="title">
      修改密码
    </div>
    <div class="form">
      <el-row :gutter="20">
        <el-col :span="6">
          <span>*</span> 原密码
        </el-col>
        <el-col :span="18">
          <el-input type="password" placeholder="请输入原密码" v-model="form.old_password" maxlength="99"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <span>*</span> 新密码
        </el-col>
        <el-col :span="18">
          <el-input type="password" placeholder="请输入新密码" v-model="form.new_password" maxlength="99"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <span>*</span> 确认新密码
        </el-col>
        <el-col :span="18">
          <el-input type="password" placeholder="请确认新密码" v-model="form.re_new_password" maxlength="99"></el-input>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6">
        </el-col>
        <el-col :span="18">
          <el-button type="primary" @click="submit">保存</el-button>
          <el-button type="danger" @click="back">取消</el-button>
        </el-col>
      </el-row>
    </div>
  </section>
</template>
<script>
import { req, errorMsg } from '@/assets/js/util.js'

export default {
  data() {
    return {
      form: {
        old_password: '',
        new_password: '',
        re_new_password: ''
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    getData() {
      return this.form
    },
    checkData() {
      if (!this.form.old_password) {
        errorMsg('请填写旧密码!')
        return false
      }
      if (!this.form.new_password) {
        errorMsg('请填写新密码!')
        return false
      }
      if (!this.form.re_new_password) {
        errorMsg('请确认新密码!')
        return false
      }
      // if (this.form.new_password.length < 7) {
      //   errorMsg('密码格式应为6-16位数字/字母/下划线!')
      //   return false
      // }
      if (this.form.re_new_password !== this.form.new_password) {
        errorMsg('两次输入的密码不一致!')
        return false
      }
      if (this.form.old_password === this.form.new_password) {
        errorMsg('新密码与旧密码不能一致!')
        return false
      }
      return true
    },
    submit() {
      if (!this.checkData()) { return false }
      this.postData()
    },
    // submit() {
    //   if (!this.checkData()) { return false }
    //   this.$confirm('确认发布?', '提示', {
    //     type: 'warning'
    //   }).then(() => {
    //     this.postData(1, 1)
    //   })
    // },
    postData() {
      req({
        url: '/manage?cmd=100',
        param: {
          old_password: this.form.old_password,
          new_password: this.form.new_password
        }
      }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    getDetail() {
      req({
        url: '/manage?cmd=105',
        param: {
          admin_id: this.admin_id
        }
      }).then(res => {
        this.form.name = res.data.data.name
        this.form.mobile = res.data.data.mobile
      })
    }
  },
  created() {
    this.admin_id = this.$route.query.admin_id || 0
    if (this.admin_id) {
      this.getDetail()
    }
  }
}
</script>
<style lang="less" scope>
.section-add {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  .title {
    width: 100%;
    border-bottom: 1px solid #e4e1e1;
    padding: 17px 0 18px 0;
    text-align: center;
  }
}
.back {
  position: absolute;
  left: 15px;
  top: 10px;
}
.form {
  width: 600px;
  margin: 20px 30px;
  .el-row {
    padding: 10px 0;
    .el-col-6 {
      text-align: right;
      padding-top: 6px;
      span {
        color: red;
      }
    }
  }
}
.el-upload-list__item-name {
  display: none!important;
}
.el-upload-list.el-upload-list--picture {
  width: 100px;
}
</style>
