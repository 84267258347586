<template>
  <section class="h100">
    <h2 class="main-title">
      {{dept_name  || '账号管理' }}
    </h2>
    <div class="condition">
      <el-button type="primary" size="medium" @click="addNew">新建账号</el-button>
      <el-button size="medium" @click="importDialog = true">导入excel</el-button>
      <el-input v-model="keyword" placeholder="请输入姓名/联系方式" style="float: right" suffix-icon="el-icon-search"></el-input>
    </div>
    <!-- end cond -->
    <div class="table">
      <template>
        <el-table height="100%" v-loading="loading" :data="info.list_item" border style="width: 100%" @selection-change="getSelected">
          <!-- <el-table-column v-if="showOption('jlmanage_delete_build') || true" type="selection" width="40"></el-table-column> -->
          <el-table-column prop="name" label="姓名" width=""></el-table-column>
          <el-table-column prop="dept_name" label="部门" width=""></el-table-column>
          <el-table-column prop="mobile" label="联系方式" width=""></el-table-column>
          <el-table-column label="操作" width="">
            <template slot-scope="scope">
              <el-button @click.stop="edit(scope.row)" type="text" size="small">修改</el-button>
              <el-button @click.stop="del(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <!-- end table -->
    <Pagi v-if="!loading" :page="page" :pageSize="pageSize" :count="info.list_info.total_num" @pageChange="pageChange" />
    <!-- end page -->
    <Upload :show="importDialog" :templateUrl="templateUrl" uploadUrl="/publicClass?cmd=100" importUrl="/user?cmd=108" @updateVisible="(val) => {this.importDialog = val}"/>
  </section>
</template>
<script>
import Pagi from '@/components/pagi/'
import Upload from '@/components/upload/'
import { req } from '@/assets/js/util.js'

export default {
  props: {
    dept_id: String,
    dept_name: String
  },
  data () {
    return {
      power: [],
      keyword: '',
      page: 1,
      pageSize: 20,
      loading: true,
      info: [],
      selected: [],
      importDialog: false,
      templateUrl: ''
    }
  },
  components: {
    Pagi,
    Upload
  },
  methods: {
    // getPower () {
    //   this.$common.req('/index.php?model=jlmanage&m=power&a=index&cmd=101', {}, res => {
    //     this.power = res.data.data
    //   })
    // },
    showOption (undoStr) {
      return this.power.indexOf(undoStr) !== -1
    },
    getLists (page) {
      req({
        url: '/user?cmd=104',
        param: {
          dept_id: this.dept_id || '',
          keyword: this.keyword,
          page: page || 1,
          page_size: this.pageSize
        }
      }).then(res => {
        this.loading = false
        this.info = res.data.data
        this.templateUrl = this.info.import_demo
        // this.getPower()
      })
    },
    pageChange (val) {
      this.page = val
      document.documentElement.scrollTop = 0
      this.getLists(val)
    },
    edit (row) {
      this.$router.push('/userAdd/?user_id=' + row.user_id)
    },
    getSelected (arr) {
      this.selected = arr
    },
    del (row) {
      this.delFun(row)
    },
    // bacth_del () {
    //   if (this.selected.length === 0) {
    //     this.$common.error('请先选择要删除的数据!')
    //   } else {
    //     let ids = []
    //     for (let index in this.selected) {
    //       ids.push(this.selected[index]['build_id'])
    //     }
    //     this.delFun(ids)
    //   }
    // },
    delFun (row) {
      this.$confirm('您确定要删除账号"' + row.name + '"吗? 删除后，此账号将不可使用!', '提示', {
        type: 'danger'
      }).then(() => {
        req({
          url: '/user?cmd=106',
          param: {
            user_id: row.user_id
          }
        }).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.getLists(Math.min(this.page, Math.ceil((this.info.list_info.total_num - 1) / this.pageSize)))
        })
      })
    },
    addNew () {
      this.$router.push('/userAdd')
    },
    reset(row) {
      this.$confirm('确认重置密码?', '提示', {
        type: 'warning'
      }).then(() => {
        req({
          url: '/manage?cmd=102',
          param: {
            admin_id: row.admin_id
          }
        }).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
      })
    }
  },
  watch: {
    'keyword': function () {
      this.getLists()
    },
    'dept_id': function () {
      this.getLists()
    }
  },
  created () {
    this.getLists()
  }
}
</script>
<style lang="less" scoped>
.condition {
  font-size: 14px;
  margin-bottom: 10px;
}
.el-input {
  width: 250px;
}
.table {
  margin: 15px 0;
}
.el-row {
  margin: 10px 0;
}
.lh28 {
  line-height: 28px;
}
</style>
<style lang="less">
#build-edit {
  .el-dialog__body {
    padding: 10px 25px;
  }
  .el-input__inner {
    font-size: 14px;
  }
  .lh34 {
    line-height: 34px;
    text-align: right;
  }
}
</style>
