<template>
  <div class="providerManage">
    <el-container>
      <el-aside width="260px">
        <div class='title'>
          组织架构 <el-button @click="add()" size="mini" type="primary" style="float: right;margin-right:5px;">新增根部门</el-button>
        </div>
        <el-tree ref="tree" :props="defaultProps" :data="treeData" node-key="id" :default-expand-all="true" @node-click="checkChange" :expand-on-click-node="true" empty-text="加载中...">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ data.name }}</span>
            <span class="btn-area">
              <span type="text" ref="editor" @click.stop="modify(node, data)">
                <i class="el-icon-edit"></i>
              </span>
              <span type="text" ref="del" @click.stop="del(node, data)">
                <i class="el-icon-delete"></i>
              </span>
              <span type="text" ref="add" @click.stop="add(node, data)" v-if="data.lev < 3 || !data.lev">
                <i class="el-icon-circle-plus-outline"></i>
              </span>
            </span>
          </span>
        </el-tree>
      </el-aside>
      <el-main>
        <!-- <router-view :childID='childID' :childName="childName" :basepaName='basepaName' @updateBase="updateBase"></router-view> -->
        <accountList :dept_id="dept_id" :dept_name="dept_name"></accountList>
      </el-main>
    </el-container>
    <el-dialog :title="confirmData.title" center class='delPro' :visible.sync="confirmData.st" width="30%">
      <span>{{confirmData.msg}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmData.st = false">取 消</el-button>
        <el-button type="danger" @click="confirmData.cb">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="editDialog.title" class="addormodify" :visible.sync="editDialog.st" width="30%">
      <!-- <el-row :gutter="20" v-show="editDialog.param.p_base_name">
        <el-col :span="3">上级</el-col>
        <el-col :span="10">{{editDialog.param.p_base_name}}</el-col>
      </el-row> -->
      <el-row :gutter="20" style="margin-top: 15px;">
        <el-col :span="3" style="line-height: 30px;">名称</el-col>
        <el-col :span="10">
          <el-input placeholder="请输入部门名称" maxlength=20 v-model="editDialog.param.name" size="small"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialog.st = false">取 消</el-button>
        <el-button type="primary" @click="editDialog.cb()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { req, errorMsg, trim } from '@/assets/js/util.js'
import accountList from '@/components/accountList/index.vue'

export default {
  data: function() {
    return {
      childID: '',
      childName: '',
      // mrc
      defaultProps: {
        label: 'name',
        children: 'sub',
        isLeaf: 'leaf'
      },
      baseData: '',
      textBase: '',
      // 默认父级
      basepaName: '',
      node: '',
      initTreeData: [],
      confirmData: {
        st: false,
        title: '',
        msg: '',
        cb: () => {}
      },
      editDialog: {
        title: '编辑部门',
        st: false,
        param: {
        },
        cb: () => {}
      },
      allDepts: [],

      treeData: [],
      dept_id: '',
      dept_name: ''
    }
  },
  components: {
    accountList
  },
  methods: {
    async loadNode(node, resolve) {
      let res = await req({
        url: '/user?cmd=100&qy'
      })
      this.treeData = res.data.data
      // if (node.level === 0) {
      //   this.getTree(data => {
      //     this.initTreeData = data
      //     this.allDepts = data
      //     return resolve(data)
      //   })
      // }
      // if (node.level === 1) {
      //   let child = this.initTreeData[0]['children']
      //   this.basepaName = this.initTreeData[0]['name']

      //   return resolve(this.setLeaf(child))
      // }
      // if (node.level > 1 && node.level < 3) {
      //   if (node.data.isParent) {
      //     this.getTreeOver2(node.data.id, data => {
      //       this.allDepts = this.allDepts.concat(data)
      //       return resolve(data)
      //     })
      //   } else {
      //     return resolve([])
      //   }
      // }
      // if (node.level === 3) {
      //   return resolve([])
      // }
    },
    // getTree(cb) {
    //   this.$common
    //     .req('/index.php?model=member&m=ajax&cmd=125&target=main-tree')
    //     .then(res => {
    //       console.log(res)
    //       window['deptId'] = res.data.dept[0].id
    //       cb(res.data.dept)
    //       // this.childID = res.data.dept[0].id
    //       // setTimeout(() => {
    //       //   this.childID = res.data.dept[0].id
    //       //   document.getElementsByClassName('el-tree-node__expand-icon')[0]['click']()
    //       // }, 250)
    //     })
    // },
    del(node, data) {
      console.log(node)
      this.confirmData = {
        st: true,
        title: '提示',
        msg: '确定删除部门?',
        cb: () => {
          this.delFun(data.dept_id)
        }
      }
    },
    delFun(id) {
      req({
        url: '/user?cmd=102&qy',
        param: {
          dept_id: id
        }
      }).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 1000
        })
        this.confirmData.st = false
        this.loadNode()
      })
    },
    checkChange(data) {
      console.log(data)
      this.dept_id = data.dept_id
      this.dept_name = data.name
    },
    updateBase() {
      this.checkChange({
        id: this.initTreeData[0]['id'],
        name: this.initTreeData[0]['name']
      })
    },
    modify(node, data) {
      console.log(data)
      this.editDialog = {
        title: '编辑部门',
        st: true,
        param: JSON.parse(JSON.stringify(data)),
        cb: () => {
          this.modifyFun()
        }
      }
    },
    modifyFun() {
      if (!trim(this.editDialog.param.name)) {
        errorMsg('名称不能为空!')
        return false
      }
      var param = this.editDialog.param
      req({
        url: '/user?cmd=101',
        param: param
      }).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 1000
        })
        this.editDialog.st = false
        this.loadNode()
      })
    },
    getPBaseName(pid) {
      let name = ''
      for (var index in this.allDepts) {
        if (name) break
        let targ = this.allDepts[index]
        if (targ['id'] === pid) {
          name = targ['name']
          break
        } else {
          if (targ['children'] && targ['children'].length > 0) {
            let children = targ['children']
            for (var index2 in children) {
              if (children[index2]['id'] === pid) {
                name = children[index2]['name']
                break
              }
            }
          }
        }
      }
      return name
    },
    add(node, data) {
      let deptId = data ? data.dept_id : 0
      this.editDialog = {
        title: '新增部门',
        st: true,
        param: {
          parent_id: deptId,
          name: '',
          dept_id: 0
        },
        cb: () => {
          this.addFun()
        }
      }
    },
    addFun() {
      if (!trim(this.editDialog.param.name)) {
        errorMsg('名称不能为空!')
        return false
      }
      var param = this.editDialog.param
      param.dept_id = 0
      console.log(param)
      req({
        url: '/user?cmd=101',
        param: param
      }).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 1000
        })
        this.editDialog.st = false
        this.loadNode()
      })
    },
    // getTreeOver2(id, cb) {
    //   this.$common
    //     .req('/index.php?model=member&m=ajax&cmd=130', {
    //       id: id
    //     })
    //     .then(res => {
    //       cb(this.setLeaf(res.data.dept))
    //     })
    // },
    setLeaf(arr) {
      for (var index in arr) {
        if (arr[index].lev === '3') {
          arr[index].leaf = true
        } else {
          if (arr[index].isParent) {
            arr[index].leaf = false
          } else {
            arr[index].leaf = true
          }
        }
      }
      return arr
    }
  },
  created() {
    this.loadNode()
  },
  mounted() {
  },
  destroyed () {
  }
}
</script>
<style lang="less" scoped>
.providerManage {
  .el-dialog__body {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
  }
  // min-width: 1200px;
  // max-width: 1400px;
  // margin: 0 auto;
  overflow: hidden;
  // margin: 30px auto;
  .title {
    padding: 15px 0 7px 15px;
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #4a4a4a;
    border: 1px solid #ececec;
  }
  .el-container {
    min-width: 1200px;
    min-height: calc(100vh - 100px);
    overflow: hidden;
    border: 1px solid #ececec;
    .el-main {
      overflow: hidden;
    }
    .el-aside {
      width: 260px;
      opacity: 0.9;
      background: #f6f6f6;
      .el-menu {
        background: #f6f6f6;
        .el-menu-item {
          padding: 0 !important;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #4a4a4a;
          height: 52px;
          a {
            display: block;
            width: 100%;
            height: 100%;
            color: #4a4a4a;
            text-decoration: none;
            text-indent: 20px;
          }
        }
      }
    }
    .el-main {
      background: #ffffff;
      padding: 0;
    }
  }
}
.providerManage .router-link-exact-active {
  outline: 0;
  color: #3a8ee6;
  background-color: #ecf5ff;
  border-right: 2px solid #3a8ee6;
}
.el-tree {
  padding: 21px 0 0 16px;
  background: #f6f6f6 !important;
}
.custom-tree-node::before {
  // content: "";
  // background-size: 10px 10px;
  // width: 10px;
  // display: inline-block;
  // height: 10px;
  // padding-right: 4px;
  // background-repeat: no-repeat;
}
.custom-tree-node:hover .btn-area .el-button {
  color: #fff;
}
.custom-tree-node {
  .btn-area {
    text-align: center;
    display: none;
    height: 21px;
    width: 70px;
    .el-button {
      // padding:0 5px !important;
      height: 21px !important;
    }
  }
  &:hover {
    .btn-area {
      background: #3a8ee6;
      border-radius: 4px;
      display: inline-block;
      // font-size:0;
      span {
        width: 29%;
        margin-left: 0;
        display: inline-block;
        color: #fff;
        i:hover {
          background: rgb(102, 177, 255);
        }
      }
       span:last-child {
        i:last-child {
          padding: 2px 2px 2px 3px;
        }
      }
      span:nth-child(2) {
        i {
          padding: 2px 0 2px 4px;
        }
      }
      span:first-child {
        i:first-child {
          padding: 2px 0 2px 3px;
        }
      }
    }
  }
}
</style>
